import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Typography } from '@material-ui/core'

import ScreenLayout from '../components/layouts/ScreenLayout'
import { Row } from '../components/shared'
import ProDashboard from './dashboard/ProDashboard'

interface Props {
  store?: any
}

@inject('store')
@observer
class Dashboard extends Component<Props> {
  render() {
    return (
      <ScreenLayout>
        <Row minHeight={45}>
          <Typography variant={'h4'}>Dashboard</Typography>
        </Row>
        <Box m={4} />
        <ProDashboard />
      </ScreenLayout>
    )
  }
}

export default Dashboard
