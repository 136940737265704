import { observer } from 'mobx-react'
import Link from 'next/link'
import React, { Component } from 'react'
import { Box } from '@material-ui/core'

import { CustomTable } from '../../components/shared'
import { $ProfessionalAnalytics } from '../../types'
import { difference } from '../../helpers/analytics'
import { currencyFormat } from '../../shared-lib/currency'

interface Props {
  model: any
}

@observer
class ProfessionalList extends Component<Props> {
  // Form columns
  columns = [
    {
      key: 'actions',
      title: 'Action',
      component: (row: $ProfessionalAnalytics) => <Box display='flex'>
        <Link href='/professionals/[id]' as={`/professionals/${row._id}?tab=8`}><a>View</a></Link>
      </Box>,
    },
    {
      key: 'name',
      title: 'Name',
      component: (row: $ProfessionalAnalytics) => {
        return `${row.firstName}${row.lastName && row.lastName.length > 0 ? ` ${row.lastName}` : ''}`
      },
    },
    {
      key: 'rating',
      align: 'center',
      component: (row: $ProfessionalAnalytics) => {
        const { currentPeriod, previousPeriod } = row.averageReview

        if (previousPeriod) {
          return difference(currentPeriod, previousPeriod, 'fixed', '-', false)
        }
        return `${currentPeriod ? currentPeriod.toFixed(2) : currentPeriod}`
      },
    },
    {
      key: 'reviews',
      align: 'center',
      component: (row: $ProfessionalAnalytics) => {
        const { currentPeriod, previousPeriod } = row.reviewCount

        if (previousPeriod) {
          return difference(currentPeriod, previousPeriod, 'none', '0')
        }

        return `${currentPeriod}`
      },
    },
    {
      key: 'bookings',
      align: 'center',
      component: (row: $ProfessionalAnalytics) => {
        const { currentPeriod, previousPeriod } = row.numberOfBookings

        if (previousPeriod) {
          return difference(currentPeriod, previousPeriod, 'none', '0')
        }

        return `${currentPeriod}`
      },
    },
    {
      key: 'earnings',
      align: 'center',
      component: (row: $ProfessionalAnalytics) => {
        const { currentPeriod, previousPeriod } = row.earnings

        if (previousPeriod) {
          return difference(currentPeriod, previousPeriod, 'currency', '0')
        }

        return `${currencyFormat(currentPeriod)}`
      },
    },
    {
      key: 'hours',
      align: 'center',
      component: (row: $ProfessionalAnalytics) => {
        const { currentPeriod, previousPeriod } = row.hours

        if (previousPeriod) {
          return difference(currentPeriod, previousPeriod, 'none', '0')
        }

        return `${currentPeriod}`
      },
    },
    {
      key: 'favourites',
      align: 'center',
      component: (row: $ProfessionalAnalytics) => {
        const { currentPeriod, previousPeriod } = row.favourites

        if (previousPeriod) {
          return difference(currentPeriod, previousPeriod, 'none', '0')
        }

        return `${currentPeriod}`
      },
    },
    {
      key: 'repeat',
      align: 'center',
      title: 'Repeat Customers',
      component: (row: $ProfessionalAnalytics) => {
        const { currentPeriod, previousPeriod } = row.repeatCustomer

        if (previousPeriod) {
          return difference(currentPeriod, previousPeriod, 'none', '0')
        }

        return `${currentPeriod}`
      },
    },
    {
      key: 'dropped',
      align: 'center',
      title: 'Dropped Bookings',
      component: (row: $ProfessionalAnalytics) => {
        const { currentPeriod, previousPeriod } = row.droppedBookings

        if (previousPeriod) {
          return difference(currentPeriod, previousPeriod, 'none', '0')
        }

        return `${currentPeriod}`
      },
    },
  ]

  render() {
    const { model } = this.props

    return (
      <div>
        <br />
        <CustomTable model={model} columns={this.columns} />
      </div>
    )
  }
}

export default ProfessionalList
