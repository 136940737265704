import moment from 'moment'

class datesHelper {
  // Check if item is default
  isDefault = (items: Array<any>, item: any): boolean => {
    const sorted = items.sort((a: any, b: any) => {
      const dateA = typeof a.setDefaultAt === 'string' ? moment(parseInt(a.setDefaultAt, 10)) : moment(a.setDefaultAt)
      const dateB = typeof b.setDefaultAt === 'string' ? moment(parseInt(b.setDefaultAt, 10)) : moment(b.setDefaultAt)
      return dateA.isSameOrAfter(dateB) ? -1 : 1
    })
    return sorted && sorted.length > 0 && sorted[0].setDefaultAt === item.setDefaultAt
  }
}

export const xDaysAgoFormated = (days: number, from = 1) => {
  const toDate = moment (new Date()).subtract(from, 'days')
  const check = toDate.subtract(days, 'days').format('YYYY-MM-DD')
  return check
}

export default new datesHelper()
