import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { colors } from '../theme'
import { Row } from '../components/shared'
import { currencyFormat } from '../shared-lib/currency'

const colorGood = {
  color: colors.GREEN400,
}

const colorBad = {
  color: colors.RED400,
}

type Formats = 'none' | 'fixed' | 'currency'

export const difference = (current: number, previous: number, format: Formats, defaultValue: string, compareEmpty = true) => {
  let style = {}
  let spacer = ' '
  let differenceValue = ''

  if (current || previous) {
    let difference = 0

    if (current === previous) {
      style = { ...colorGood }
    } else {
      if (current > previous) {
        style = { ...colorGood }
        spacer = current === previous ? '-' : '⬆'
        difference = current - previous
      } else {
        style = { ...colorBad }
        spacer = '⬇'
        difference = previous - current
      }

      differenceValue = formatValue(difference, format, defaultValue)
    }
  }

  if (!compareEmpty && (!current || !previous)) {
    style = {}
    spacer = ' '
  }

  const currentValue = formatValue(current, format, defaultValue)

  return (<Row justifyContent='center'>
    <Typography>{currentValue}</Typography>
    {!!differenceValue && <Box ml={1}><Typography style={style} >{`${spacer}${differenceValue}`}</Typography></Box>}
  </Row>)
}

const formatValue = (value: any, format: Formats, defaultValue: string) => {
  if (!value) {
    return defaultValue
  }
  if (format === 'fixed') {
    return value.toFixed(2)
  } else if (format === 'currency') {
    return `${currencyFormat(value)}`
  }

  return `${value}`
}