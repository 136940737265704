import React, { useEffect, useState } from 'react'
import { Typography, Box } from '@material-ui/core'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import ListViewModel from '../../pageModels/ListViewModel'
import { $Filter } from '../../types'
import { Row, Filters, SearchField } from '../../components/shared'
import ProfessionalDashboardList from '../../components/tables/ProfessionalDashboardList'
import { xDaysAgoFormated } from '../../helpers/datesHelper'

/*
* View model
*/

class ViewModel extends ListViewModel {
  // Collection and queries

  baseFilters = {
    'professionalProfile.information.status': { $ne: 'trade-test' },
    blocked: false,
  }

  @observable filters: Array<$Filter> = [
    {
      label: 'Filter',
      property: 'filters',
      value: 'All',
      options: ['All', 'Featured only'],
      query: (value: any) => {
        if (value === 'All') return { ...this.baseFilters }
        else if (value === 'Featured only') return { ...this.baseFilters, 'professionalProfile.featured': true }
      },
    },
    {
      label: 'Dates',
      property: 'periods',
      value: 'Past 30 days',
      options: ['All', 'Past 30 days', 'Past 7 days'],
      query: (value: any) => {
        let periods = [
          { key: 'currentPeriod', dates: [xDaysAgoFormated(1000), xDaysAgoFormated(0)] },
          { key: 'previousPeriod', dates: [xDaysAgoFormated(0, 1000), xDaysAgoFormated(1000)] },
        ]

        if (value === 'Past 30 days') {
          periods = [
            { key: 'currentPeriod', dates: [xDaysAgoFormated(30), xDaysAgoFormated(0)] },
            { key: 'previousPeriod', dates: [xDaysAgoFormated(30, 30), xDaysAgoFormated(30)] },
          ]
        } else if (value === 'Past 7 days') {
          periods =  [
            { key: 'currentPeriod', dates: [xDaysAgoFormated(7), xDaysAgoFormated(0)] },
            { key: 'previousPeriod', dates: [xDaysAgoFormated(7, 7), xDaysAgoFormated(7)] },
          ]
        }

        return { periods: periods }
      },
    },
  ]

  // Load state
  onComponentDidMount = async (props: any, _options: any) => {
    this.store = props.store
    this.loaded = false
    this.getQueryName = 'getProfessionalsAnalytics'
    await this.loadState()
    this.loaded = true
  }
}

const ProDashboard = (props: any) => {
  const [viewModel] = useState(new ViewModel())

  useEffect(() => {
    viewModel.onComponentDidMount(props, {})
  }, [])

  const { changeFilter, filters } = viewModel

  return (
    <>
      <Row>
        <Typography variant={'h4'}>Professionals Analytics</Typography>
        <Box height={45} />
      </Row>
      <br />
      <Row alignItems={'flex-end'}>
        <Filters filters={filters} onChangeFilter={changeFilter} />
        <SearchField model={viewModel} />
      </Row>
      <br />
      <ProfessionalDashboardList model={viewModel} />
    </>
  )
}

export default inject('store')(observer(ProDashboard))
