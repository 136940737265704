import React, { FunctionComponent } from 'react'
import { colors } from '../../theme'

// Fixed height 100% layout
const ScreenLayout: FunctionComponent = ({ children }: any) => (
  <div className={'fixedLayoutRoot'}>
    {children}
    {styles}
  </div>
)

const styles = (
  <style>
    {`
      body {
        margin: 0em;
        background-color: ${colors.BG_PURPLE};
      }
      a {
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.5;
        color: ${colors.PRI900};
      }
      .fixedLayoutRoot {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 0px;
        padding: 24px;
        padding-left: 60px;
        padding-right: 60px;
      }
      @media only screen and (max-width: 600px) {
        .fixedLayoutRoot {
          flex-grow: 1;
          padding: 20px;
          margin-left: 0px;
          width: calc(100vw - 40px);
        }
      }  
    `}
  </style>
)

export default ScreenLayout
